.footer {
  background: #ececec;
  padding-top: 50px;
  padding-bottom: 20px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 122px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__copyright {
    font-size: 14px;
    padding-top: 50px;
    padding-bottom: 10px;
    text-align: center;
  }
}
