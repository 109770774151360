.service-lane {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @include bp(tablet) {
    margin-bottom: 100px;
  }

  &__item {
    padding: 20px 0;
    width: 100%;

    @include bp(tablet) {
      width: 50%;
      padding: 20px;
    }

    @include bp(desktop) {
      width: 25%;
    }
  }
}
