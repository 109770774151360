.cta {
  $self: &;
  position: relative;
  display: block;
  cursor: pointer;
  background-color: var(--c-primary-light);
  width: max-content;
  color: white;
  padding: 16px 55px;
  border-radius: 25px;
  font-size: 18px;

  @media (hover) {
    &:hover {
      #{$self}__icon {
        transform: scale(1.35);
      }
    }
    &:not(&--mail) {
      &:hover {
        #{$self}__text {
          transform: translateX(10px);
        }
      }
    }
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0;
    border-radius: 50%;
    margin-right: 15px;
  }

  &__text {
    transition: transform 0.25s var(--bounce);
    display: block;
  }

  &__icon {
    transition: transform 0.25s var(--bounce);
    width: 28px;
    height: 28px;
  }
}
