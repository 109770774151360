.section-heading {
  $self: &;
  position: relative;
  font-size: 24px;
  line-height: 28px;
  font-style: italic;
  margin-bottom: 40px;
  text-transform: uppercase;

  @at-root .lang-pl {
    .section-heading {
      hyphens: auto;
    }
  }

  &:not(&--without-line):before {
    content: "";
    width: 50%;
    max-width: 150px;
    height: 6px;
    position: absolute;
    bottom: -6px;
    left: 0;
    background: var(--gradient);
    border-radius: 6px;
  }

  @include bp(tablet) {
    font-size: 32px;
    line-height: 40px;
  }

  &--1 {
    line-height: 30px;
    width: 90%;
    font-size: calc(26px + (46 - 26) * (100vw - 400px) / (1440 - 400));
    line-height: calc(30px + (50 - 30) * (100vw - 400px) / (1440 - 400));

    @include bp(tablet) {
      width: 75%;
    }
  }
}
