.slider {
  $self: &;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 50px;
  @include bp(tablet) {
    padding-bottom: 20px;
    margin-bottom: 100px;
  }

  &__item {
    width: 90%;
    transition: opacity 300ms ease-in-out;
    opacity: 0.5;

    @include bp(tablet) {
      width: 75%;
    }

    &.swiper-slide-active {
      opacity: 1;
      #{$self}__image-bg {
        transition: opacity 0.4s 0.4s ease-in-out, transform 0.4s 0.4s ease-in-out;
        opacity: 1;
        transform: translate(10px, 10px);
        @include bp(tablet) {
          transform: translate(20px, 20px);
        }
      }
    }
  }

  &__image-wrap {
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (2 / 3) * 100%;
    }
  }

  &__image,
  &__svg,
  &__image-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__svg {
    height: 100%;
  }

  &__image-bg {
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
    background: var(--c-primary-light);
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  }

  &__btn {
    --size: 100px;
    cursor: pointer;
    background-color: var(--c-primary-dark);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    position: absolute;
    bottom: 50px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    display: none;

    @include bp(tablet) {
    }

    &--next {
      right: 10%;
    }

    &--prev {
      left: 10%;
      transform: rotate(180deg);
    }

    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }

    @media (hover) {
      &:hover:not(.swiper-button-disabled) {
        #{$self}__arrow {
          transform: translateX(10px);
        }
      }
    }

    @include bp(desktop) {
      display: flex;
    }
  }

  &__arrow {
    width: 22px;
    transition: transform 0.3s var(--bounce);
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px;
    @include bp(tablet) {
      bottom: 20px;
    }
    @include bp(desktop) {
      bottom: 50px;
    }
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 4px;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1.3);
  }
  .swiper-pagination-bullet {
    background: var(--c-primary-light);
    opacity: 1;
    width: 10px;
    height: 10px;
    margin: 5px 0;

    @include bp(tablet) {
      background: white;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--c-primary-dark);
    @include bp(tablet) {
      background: var(--c-primary-light);
    }
  }
}
