.section {
  position: relative;
  margin-bottom: 50px;

  @include bp(tablet) {
    margin-bottom: 100px;
  }

  &__text-box {
    width: 100%;

    @include bp(tablet) {
      width: 65%;
    }

    @include bp(desktop) {
      width: 45%;
    }
  }

  &__text {
    margin-bottom: 30px;

    @include bp(tablet) {
      margin-bottom: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include bp(tablet) {
      flex-direction: row;
    }

    &--10 {
      @include bp(tablet) {
        width: 100% / 12 * 10;
        margin-left: 8.3333%;
      }
    }
  }

  &__social {
    display: flex;
    margin-top: 40px;

    @include bp(tablet) {
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  &--contact {
    background-color: var(--c-primary-dark);
    color: white;
    padding: 50px 0;
    overflow: hidden;
    margin-bottom: 0;

    .section-heading {
      margin-bottom: 10px;
    }
    .section__content {
      align-items: center;
      justify-content: space-between;
    }

    .section__content > div {
      width: 100%;

      @include bp(tablet) {
        width: 40%;
      }
    }
  }

  &__image {
    &--contact {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 120%;
      opacity: 0.2;
      @include bp(tablet) {
        left: unset;
        top: -115px;
        right: 0;
        transform: translate(0, 0);
        height: 500px;
      }
    }

    &--about {
      position: absolute;
      right: -20px;
      top: 0;
      z-index: -1;
      display: none;
      @include bp(tablet) {
        display: block;
        width: clamp(800px, 900px, 100%);
      }
    }
  }
}
