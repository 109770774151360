.hero-stage {
  position: relative;
  height: 50vh;

  @include bp(tablet) {
    height: 60vh;
  }

  .section-heading--1 {
    margin-top: 10vh;
    @include bp(desktop) {
      margin-left: 10px;
    }
  }
}
