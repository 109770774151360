.hero-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%);
  }

  &__svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100vh;
    opacity: 0.6;

    @media (hover) {
      width: 100vw;
      height: auto;
    }
  }

  .pulse {
    transition: fill 1s ease-in-out;
    fill: var(--c-primary-dark);
  }
}
