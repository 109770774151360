.image {
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 20px;

  &:not([alt]) {
    border: 5px solid red;
  }

  &--blur {
    -webkit-filter: blur(10px);
    filter: blur(10px);
    transition: filter 0.4s, -webkit-filter 0.4s;

    &.lazyloaded {
      -webkit-filter: blur(0);
      filter: blur(0);
    }
  }
}
