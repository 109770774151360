$bs: 0px 30px 50px -22px rgba(0, 0, 0, 0.35);
$br: 20px;

$breakpoints: (
  mobile: 414px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1280px,
  xl: 1440px,
  super: 1920px,
);

$grid: (
  default: (
    max-width: 100vw,
    outer-padding: 20px,
    gutter: 21px,
  ),
  tablet: (
    max-width: 100vw,
    outer-padding: 20px,
    gutter: 20px,
  ),
  desktop: (
    max-width: 1920px,
    outer-padding: 20px,
    gutter: 24px,
  ),
);
$font-sizes: (
  default: (
    copy: 16px,
    big-copy: 18px,
  ),
  tablet: (
    copy: 16px,
    big-copy: 18px,
  ),
  desktop: (
    copy: 16px,
    big-copy: 20px,
  ),
);

$line-height: (
  default: (
    big-copy: 22px,
    heading-copy: 24px,
  ),
  tablet: (
    big-copy: 22px,
    heading-copy: 24px,
  ),
  desktop: (
    big-copy: 24px,
    heading-copy: 26px,
  ),
);

$themes: default, dark;

$variables: (
  breakpoints: $breakpoints,
  grid: $grid,
  themes: $themes,
  font-sizes: $font-sizes,
  line-height: $line-height,
);

// functions

@function get-value($var-name, $value, $breakpoint: default) {
  $val-group: map-get($variables, $var-name);
  // Variable group does not exist
  @if ($val-group == null) {
    @error "Cannot get value group #{$var-name}";
  } @else {
    // There is a value
    // Try getting value for named breakpoint
    $resp-val: map-get($val-group, $breakpoint);
    @if ($resp-val == null) {
      // Simple value group w/o responsive definitions
      $val: map-get($val-group, $value);
      @if ($val == null) {
        $fallback: map-get(map-get($val-group, default), $value);
        @if ($fallback == null) {
          @error "Cannot get value #{$value}";
        } @else {
          @return $fallback;
        }
      } @else {
        @return $val;
      }
    } @else if(type-of($resp-val) == "map") {
      // Responsive definitions for value found
      $val: map-get($resp-val, $value);

      @if ($val == null) {
        @error "Cannot get responsive value at #{$breakpoint} for #{$var-name}";
      } @else {
        @return $val;
      }
    }
  }
}

@function get-list($name) {
  @return map-get($variables, $name);
}

@function to-list($value) {
  @return if(type-of($value) != list, ($value), $value);
}

// mixins
/* Responsive mixins */

@mixin bp($key, $widthType: min) {
  @if ($key == default) {
    @content;
  } @else {
    @if ($widthType == max) {
      @media (max-width: #{get-value(breakpoints, $key)-1}) {
        @content;
      }
    } @else {
      @media (min-width: #{get-value(breakpoints, $key)}) {
        @content;
      }
    }
  }
}

@mixin bp-attr($attr, $val_group, $val) {
  $group: map-get($variables, $val_group);
  $attr-list: to-list($attr);
  @each $bp-key, $vals in $group {
    $bp-val: map-get($vals, $val);
    @include bp($bp-key) {
      @each $single-attr in $attr-list {
        #{$single-attr}: $bp-val;
      }
    }
  }
}

@mixin gridContainer {
  box-sizing: border-box;
  @include bp-attr(max-width, grid, max-width);
  @include bp-attr((padding-left, padding-right), grid, outer-padding);
  margin: 0 auto;
}

@mixin themed-attr($attr, $vals) {
  $attr-list: to-list($attr);
  $themes: get-list(themes);
  @each $single-attr in $attr-list {
    #{$single-attr}: nth($vals, 1);
  }
  @for $i from 2 through length($vals) {
    @at-root {
      .theme--#{nth($themes, $i)} &,
      &.theme--#{nth($themes, $i)} {
        @each $single-attr in $attr-list {
          #{$single-attr}: nth($vals, $i);
        }
      }
    }
  }
}

@mixin copy {
  font-family: var(--f);
  @include bp-attr(font-size, font-sizes, copy);
  font-weight: 400;
  line-height: 1.375em;
}
@mixin big-copy {
  font-family: var(--f);
  @include bp-attr(font-size, font-sizes, big-copy);
  @include bp-attr(line-height, line-height, big-copy);
  font-weight: 700;
}

.typo {
  &--big-copy {
    @include big-copy();
  }

  &--copy {
    @include copy();
  }
}
