.service-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__image-wrapper {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 20px;
  }

  &__image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__text-wrapper {
    padding: 0 20px;
    height: 100%;
  }

  &__text-wrapper-inner {
    background: white;
    box-shadow: 0 6px 40px -6px rgba(black, 0.3);
    padding: 0 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100%;

    h4 {
      font-size: 18px;
      padding-top: 25px;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      padding-bottom: 40px;
    }
  }
}
