.header {
  $self: &;
  display: flex;
  height: 100px;
  align-items: center;
  z-index: 2;

  &__logo {
    margin-left: 20px;
    height: 90px;

    svg {
      height: 90px;
    }

    @include bp(tablet) {
      margin-left: 40px;
    }
  }

  &__nav {
    margin-left: auto;
    margin-right: 40px;
    display: none;
    @include bp(desktop) {
      display: flex;
    }
  }

  &__nav-item {
    position: relative;
    margin-right: 20px;
    font-size: 20px;
    line-height: 25px;
    color: var(--c-font);
    text-transform: uppercase;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70px;
      height: 70px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      content: "";
      opacity: 0;
      transition: transform 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8),
        opacity 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      transform: translateX(-50%) translateY(-50%) scale(0.2);
      z-index: 1;
    }

    &:before {
      width: 60px;
      height: 60px;
      border-width: 6px;
      transform: translateX(-50%) translateY(-50%) scale(0.8);
      background-color: #fff;
      z-index: 0;
    }
    @media (hover) {
      &:hover {
        &:after,
        &:before {
          opacity: 1;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }
    }

    &--active {
      &:after,
      &:before {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) scale(1);
      }
    }
  }
  &__nav-item-text {
    position: relative;
    z-index: 2;
    display: block;
  }

  &__language {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include bp(desktop) {
      margin-left: 20px;
      margin-right: 40px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition: transform 0.3s var(--bounce);
    }

    @media (hover) {
      &:hover {
        &:before {
          transform: translate(-50%, -50%) scale(1.25);
        }
      }
    }

    &--is-open {
      &:before {
        transform: translate(-50%, -50%) scale(1.2);
      }

      #{$self}__language-icon {
        transform: rotate(180deg);
      }

      #{$self}__language-nav {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__language-nav {
    position: absolute;
    top: 230%;
    left: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 6px 40px -6px rgba(black, 0.3);
    transform: translateX(-50%) translateY(-10px);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
  }

  &__language-icon {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: var(--c-primary-light);
    transition: transform 0.3s var(--bounce);

    &:after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      left: 4px;
      top: 2px;
    }
  }

  &__language-item {
    &--active {
      z-index: 2;
    }
  }

  &__language-item-link {
    color: var(--c-font);
    padding: 5px 10px;
    display: inline-block;

    @media (hover) {
      &:hover {
        background-color: var(--c-primary-light);
      }
    }
  }
}
