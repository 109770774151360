@import url("https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap");

:root {
  --c-primary-dark: #2d3f50;
  --c-primary-light: #b2b9be;
  --c-font: #131313;
  --gradient: linear-gradient(to right, #b2b9be 0%, #2d3f50 100%);
  --f: "Sanchez", serif;
  --bounce: cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

body {
  font-family: var(--f);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    transition: opacity 0.4s ease-in-out;
    z-index: 3;
  }

  &.show {
    &:before {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.svg-sprite {
  display: none;
}
