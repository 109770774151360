@import "global/index.scss";

@import "vendor/swiper.scss";

@import "components/cta.scss";
@import "components/image.scss";
@import "components/header.scss";
@import "components/section.scss";
@import "components/section-heading.scss";
@import "components/service-card.scss";
@import "components/hero-stage.scss";
@import "components/service-lane.scss";
@import "components/hero-bg.scss";
@import "components/footer.scss";
@import "components/slider.scss";

body {
  padding: 0;
}

.dots {
  display: none;
}
